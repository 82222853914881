 .hero-wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0;
}

.hero {
  grid-row: 1 / 2;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 58%;
  z-index: 1; 
}

.hero video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
} 


/* For tablets */
@media (max-width: 768px) {
  .hero {
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
}

/* For specific device width and height */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .hero {
    padding-top: 58%; /* 4:3 Aspect Ratio, you can adjust this as needed */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .hero {
    padding-top: 58%; /* 4:3 Aspect Ratio, you can adjust this as needed */
  }
}




.gradient-text, .AI-powered {
  background: linear-gradient(to right, rgb(52, 87, 225), rgb(157, 63, 215), rgb(241, 151, 211));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.content {
  grid-row: 2 / 3;
  margin: 0;
  padding: 4rem;
  background: linear-gradient(to right, #ab5f7d, #dfacdd);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content .card p {
  color: whitesmoke;
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 1em;
}

.content .card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2em;
  border-radius: 10px;
  max-width: 900px;
  color: white;
}

.AI-powered {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

@media (max-width: 600px) {
  .AI-powered {
    font-size: 2rem;
  }

  .content {
    padding: 2rem;
  }

  .content .card p {
    font-size: 1.2rem;
    margin-bottom: 0.8em;
  }

  .content .card {
    padding: 1em;
  }
}

@media (min-width: 1024px) {
  .AI-powered {
    font-size: 4rem;
  }

  .content .card p {
    font-size: 2rem;
    margin-bottom: 1.5em;
  }

  .content .card {
    padding: 3em;
  }
}

.content .how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 2rem 0;
  counter-reset: step;
  max-width: 50%;
  width: 100%;
}

.content .how-it-works li {
  list-style-type: none;
  padding: 1rem;
  color: whitesmoke;
  font-size: 20px;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  perspective: 1000px;
}

.content .how-it-works li:before {
  content: counter(step) ".";
  counter-increment: step;
  font-size: 2rem;
  margin-right: 1rem;
  color: rgb(250, 250, 250);
}

.content .how-it-works li .icon {
  color: whitesmoke;
  font-size: 2rem;
  margin-right: 1rem;
}

.content .how-it-works li .step-details {
  color: whitesmoke;
  font-size: 1.2rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: auto;
  max-height: 200px;
}

.content .how-it-works li:hover {
  background: rgba(255, 255, 255, 0.4);
  transform: scale(1.05);
}

.content .how-it-works .flip-card-inner {
  transition: transform 0.5s;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

.content .how-it-works .flip-card-front,
.content .how-it-works .flip-card-back {
  backface-visibility: hidden;
}

.content .how-it-works .flip-card-back {
  transform: rotateY(180deg);
}

.content .how-it-works .flip-card-inner.is-flipped {
  transform: rotateY(180deg);
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #52A1C3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #72B2D4;
}

.dark-theme {
  background-color: #333;
  color: #f0f0f0;
}

.section {
  background: rgba(255, 255, 255, 0.1);
  padding: 2em;
  border-radius: 10px;
  max-width: 900px;
  color: white;
  text-align: left;
  margin-bottom: 2rem;
}

.section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  text-decoration: underline;
}

.section .content {
  font-size: 1.2rem;
  background: rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 5px;
  overflow: auto;
  max-height: 200px;
  transition: all 0.3s ease-in-out;
}

.how-it-works-title span {
  position: relative;
  z-index: 1;
  background: linear-gradient(to right, #ab5f7d, #dfacdd);
  padding: 0 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.waitlist-button-container {
  position: absolute;
  top: 50%;
  left: 27%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.waitlist-button {
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border: 0;
  border-radius: 3px;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  transition: all 0.3s ease;
}

.waitlist-button:hover {
  box-shadow: 0 5px 7px 2px rgba(255, 105, 135, 0.4);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .waitlist-button.MuiButton-root {
    width: 100%;
    height: auto;
    padding: 0 10px;
    font-size: 8px;
  }
}

/* Default style for larger screens */
.hero-image-container {
  display: flex;
  justify-content: center;
  overflow: hidden; /* This will ensure the image covers the entire container */
}

.hero-image-container img {
  width: 100%;
  height: 100%; /* This will make the image take the full height of the container */
  object-fit: cover; /* This will make the image cover the container without stretching */
}

/* For tablets */
@media (max-width: 768px) {
  .hero-image-container img {
    /* You can adjust the properties here as needed for tablet view */
  }
}

/* For mobile devices with specific dimensions */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .hero-image-container img {
    /* Adjust properties here for specific mobile view */
  }
}

/* For general mobile devices */
@media (max-width: 480px) {
  .hero-image-container img {
    /* Adjust properties here for general mobile view */
  }
}
