.container {
  margin-top: 20px;
}

.list-item {
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Typography for Mentor Names */
.list-item .MuiTypography-h6 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Typography for other mentor details */
.secondary-text {
  color: #555;
  margin-bottom: 5px;
}

/* Media Queries */

/* For tablets and larger screens */
@media (min-width: 768px) {
  .list-item {
    margin: 20px;
  }
  .list-item .MuiTypography-h6 {
    font-size: 1.8em;
  }
}

/* For desktops and larger screens */
@media (min-width: 1024px) {
  .list-item {
    margin: 30px;
  }
  .list-item .MuiTypography-h6 {
    font-size: 2em;
  }
}
