.features-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
}

.features-title {
  color: #111;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
}

.features-icon-box svg {
  font-size: 2rem;
  color: #6c63ff;
}

.features-card {
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.07);
  transition: all 0.3s ease-out;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
}

.features-card:hover {
  transform: scale(1.02);
  box-shadow: 0 24px 36px 0 hsla(0, 0%, 0%, 0.11);
}

.features-card-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.features-icon-box {
  margin-bottom: 1.5rem;
}

.features-icon-avatar {
  background-color: #6c63ff;
  color: #ffffff;
  width: 50px;
  height: 50px;
}

.features-description {
  color: #777;
  line-height: 1.5;
  margin-top: 1rem;
}
