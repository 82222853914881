.waitlist-form {
  width: 90%;
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.waitlist-form h1 {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  text-align: center;
}

.waitlist-form p {
  margin-bottom: 2rem;
  text-align: center;
}

.waitlist-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.waitlist-form form .MuiButtonBase-root {
  margin-top: 1rem;
}
