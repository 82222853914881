body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  background: #f9f9f9;
}

.ProductPageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3em 0;
  background-color: #f9f9f9;
  color: #333;
}

.Section {
  width: 80%;
  margin: 3em auto;
  text-align: center;
}

.ImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 2em;
}

.ImageContainer img {
  max-width: 100%;
  height: auto;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

h1,
h2,
h4 {
  color: #333;
}

h1 {
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 0.5em;
}

h4 {
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 0.5em;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 0.5em 0;
}

p,
li {
  font-size: 1em;
  line-height: 1.6em;
  color: #666;
}

.key-feature {
  margin: 1em 0;
  font-weight: bold;
}

.AI-powered,
.gradient-text {
  margin-bottom: 2em;
  background: linear-gradient(
    to right,
    rgb(52, 87, 225),
    rgb(157, 63, 215),
    rgb(241, 151, 211)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

@media (max-width: 600px) {
  .Section {
    width: 95%;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1em;
  }

  p,
  li {
    font-size: 0.9em;
  }

  .ProductPageContainer {
    padding: 2em 0;
  }
}

/* Add margin between image and list items */
.Section ul {
  margin-top: 1em;
}

.gradient-text {
  background: linear-gradient(90deg, #fc466b, #3f5efb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-top: 4em; /* Add margin on top */
}
