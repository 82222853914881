body {
  font-family: 'Garet', sans-serif;
  background-color: #ffffff;
  color: #192b93;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 2em;
}

.video-section {
  width: 100%;
  height: auto; /* Adjust height if necessary */
  position: relative;
  display: block;
  overflow: hidden;
}

.hero-video {
  width: 100%;
  height: auto; /* You can adjust the height and width based on your preference */
  display: block;
}

.core-program-section {
  margin: 3rem 0;
  padding: 2rem;
  background-color: #f7f7f7;
  border-radius: 10px;
}

/* ... Rest of the CSS ... */


.core-program-section h3,
.core-program-section h4 {
  font-weight: bold;
  margin-bottom: 1rem;
  color: #4553a4;
}

.core-program-section p {
  margin-bottom: 1rem;
  font-size: 1em;
  line-height: 1.5;
  color: #192b93;
}

/* ... Rest of the CSS ... */




.about-cta {
  padding: 2rem 0;
  text-align: center;
}

.about-cta button {
  background: linear-gradient(to right, rgb(52, 87, 225), rgb(157, 63, 215), rgb(241, 151, 211));
  color: #ffffff;
  font-size: 1.2em;
  padding: 10px 30px;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.about-cta button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.about-cta button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 30px;
  margin: 20px 0;
}

.card h5 {
  font-size: 1.5em;
  color: #8d264f;
  margin-bottom: 20px;
}

.card p {
  font-size: 1em;
  color: #192b93;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .core-program-section {
    padding: 1rem;
  }

  /* Additional mobile-responsive adjustments can be made here */
}
