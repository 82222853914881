/* Dashboard.css */

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
}

.dashboard-header {
  font-size: 2em;
  font-weight: 600;
  color: #333;
  margin-bottom: 1em;
}

.dashboard-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.dashboard-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.dashboard-item h3 {
  font-size: 1.2em;
  font-weight: 500;
  color: #333;
}

.dashboard-item p {
  font-size: 1em;
  color: #666;
}

.dashboard-item .icon {
  font-size: 1.5em;
  color: #333;
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 1em;
  }

  .dashboard-header {
    font-size: 1.5em;
  }

  .dashboard-item h3 {
    font-size: 1em;
  }

  .dashboard-item .icon {
    font-size: 1.2em;
  }
}

@media (min-width: 1200px) {
  .dashboard-container {
    padding: 3em;
  }

  .dashboard-header {
    font-size: 2.5em;
  }

  .dashboard-item h3 {
    font-size: 1.5em;
  }

  .dashboard-item .icon {
    font-size: 2em;
  }
}
/* Dashboard.css */

.chart-section {
  width: 100%;
  padding: 1em;
}

@media (max-width: 768px) {
  .chart-section {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .chart-section .chart {
    width: 100%;
    height: auto;
  }

  .dashboard-container {
    padding: 0.5em;
  }

  .dashboard-header {
    font-size: 1.2em;
  }

  .dashboard-item h3 {
    font-size: 0.9em;
  }

  .dashboard-item .icon {
    font-size: 1em;
  }
}
