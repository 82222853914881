@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
.footer {
  margin-bottom: 0;
}

.footer-link {
  margin-bottom: 8px;
}

.footer {
  color: #fff;
  background-image: linear-gradient(
    90deg,
    rgba(39, 27, 111, 1) 0%,
    rgba(87, 25, 45, 1) 100%
  );
  position: relative;
  width: 100%;
  padding: 50px;
}

.footer-content {
  margin: 0 auto;
  max-width: 1200px;
}

@media (max-width: 960px) {
  .footer {
    width: 100%;
    top: auto;
  }

  .footer-content {
    padding: 0 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
  }

  .slogo {
    grid-column: 1/3;
  }

  .office {
    grid-column: 3/5;
  }
  .link {
    grid-column: 1/3;
  }
}

@media (max-width: 660px) {
  .footer-content {
    gap: 10px;
    padding: 0;
  }

  .icons {
    display: flex;
  }

  .row {
    grid-column: 1/4;
  }
}

.slogo {
  width: 120px;
}

.main {
  margin: 50px;
  padding-top: 20px;
  padding-bottom: 50px;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(4, 1fr);
}

.row {
  margin-top: 50px;
}

.footer-header {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.contact-des {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-des a {
  text-decoration: none;
  color: #98a8f8;
}
.contact-des p {
  max-width: 350px;
  color: whitesmoke;
}
.contact-des a:hover {
  color: #fff;
}

.num {
  font-size: 1rem;
  font-weight: 500;
}

.link-des {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link-des a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.link-des a:hover {
  color: #fb2576;
  transition: linear 0.3s;
}

.btn-know {
  text-decoration: none;
  background-color: #fff;
  padding: 10px;
  /* position: relative; */
  /* top: 30px; */
  background-color: transparent;
  border: 1px solid #98a8f8;
  color: #98a8f8;
  text-transform: uppercase;
  border-radius: 10px;
}

.btn-know:hover {
  color: white;
  background-color: #98a8f8;
  transition: linear 0.3s;
}

.subscribe {
  display: flex;
}

input[type='mail'] {
  border: none;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  font-size: 0.8rem;
}

.sub-icon {
  margin: 7px;
  margin-right: 15px;
  margin-left: 0;
  font-size: 1.5rem;
}

.ri-arrow-right-line {
  margin-left: 0;
}

.icons a {
  text-decoration: none;
  color: #fff;
  border: 1px solid #fff;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  align-items: center;
  font-size: 1.1rem;
  margin: 2%;
}

.social-icon {
  position: relative;
  top: 2px;
}

.icons a:hover {
  color: #fff;
  background-color: #fb2576;
  border-color: #fb2576;
}

.newsletter-des {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.copy-right {
  text-align: center;
}

hr {
  width: 90%;
  margin: 2px auto;
  opacity: 40%;
  border: 0.01px solid white;
}

.copy-right p {
  padding: 20px;
}

/* existing styles */

/* add media query for larger screen sizes */
@media (min-width: 1200px) {
  .main {
    grid-template-columns: repeat(4, 1fr);
  }

  .contact-des p {
    max-width: 350px;
  }
}

/* Adjustments for medium screens (tablets, large phones) */
@media (max-width: 960px) {
  .footer {
    padding: 20px;
  }

  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
  }

  .slogo,
  .office,
  .link {
    grid-column: 1/3;
  }

  .row {
    margin-top: 40px;
  }

  .footer-header {
    margin-bottom: 30px;
  }

  .contact-des p {
    max-width: 100%; /* allow the text to fill the container on smaller screens */
  }
}

/* Adjustments for small screens (phones, small devices) */
@media (max-width: 660px) {
  .footer-content {
    padding: 20px 10px;
    grid-template-columns: 1fr;
  }

  .row {
    margin-top: 30px;
  }

  .footer-header {
    margin-bottom: 30px;
  }

  .contact-des p {
    max-width: 100%; /* allow the text to fill the container on smaller screens */
  }
}
