.metrics-container {
  margin: 2em 0;
  padding: 0 2em; /* Add padding to the left and right */
}

.metric-card {
  padding: 1em;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.2s;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.metric-card:hover {
  transform: scale(1.05);
}

.metric-title {
  color: #333;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.metric-value {
  font-size: 2em;
  font-weight: 600;
  color: #ca9bae;
  margin-bottom: 0.5em;
}

/* Media Queries */
@media (max-width: 768px) {
  .metric-card {
    padding: 0.5em;
  }

  .metric-value {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .metric-card {
    padding: 0.5em;
  }

  .metric-value {
    font-size: 1.2em;
  }

  .metrics-container {
    padding: 0 1em; /* Reduce padding for smaller screens */
  }
}
